<template>
  <div class="video-preview">
    <iframe
      :src="url"
      :width="width"
      :height="height"
      frameborder="0"
      vspace="0"
      hspace="0"
      allowfullscreen="true"
    ></iframe>
  </div>
</template>

<script>

export default {
  name: "VideoPreview",
  props: {
    url: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 940,
    },
    height: {
      type: Number,
      default: 529,
    },
  },
};
</script>

<style lang="scss" scoped>
.video-preview {
  width: 100%;
  iframe {
    width: 100%;
  }
}
</style>
