<template>
  <div class="container">
    <h1 class="press-title">{{ page.fields.press_title }}</h1>
    <video-placeholder-card v-if="page.fields.video_list"
                            :videoList="page.fields.video_list" />
    <external-news v-if="page.fields.external_news"
                  :newsList="page.fields.external_news" />
  </div>
</template>

<script>
import {
  butter
} from '@/buttercms';
import VideoPlaceholderCard from '@/components/basic/VideoPlaceholderCard.vue';
import ExternalNews from '@/components/basic/ExternalNews.vue';

export default {
  name: 'Press',
  components: {
    VideoPlaceholderCard,
    ExternalNews,
  },
  data() {
    return {
      title: 'Press',
      page: {
        slug: '',
        fields: {},
      },
    };
  },
  methods: {
    fetchPage() {
      const locale = this.$route.params.locale;
      butter.page.retrieve('press', 'press', { locale: locale })
        .then((res) => {
          this.page = res.data.data;
        });
    },
  },
  created() {
    this.fetchPage();
  },
};
</script>
<style scoped lang="scss">
.container {
  padding-bottom: 3rem;
}
.press-title {
  text-align: center;
  font-size: 4rem;
  display: block;
  line-height: 1.3em;
  margin: 4rem 0 6rem;

  @include bp-xsmall {
    padding: 1.25rem 1rem;
  }
  @include bp-small {
    padding: 1.25rem 1rem;
  }
  @include bp-medium {
    padding: 1rem 3rem;
  }
  @include bp-large {
    padding: 1rem 4rem;
  }
}

</style>
